function checkCase(str) {
  let hasInt = false;
  let hasUppercase = false
  let hasLowercase = false

  for(var x = 0; x < str.length; x++){
    if (!isNaN(str[x] * 1)){
      hasInt = true
    } else {
      if (str[x] == str[x].toUpperCase()) {
        hasUppercase = true
      }
      if (str[x] == str[x].toLowerCase()){
        hasLowercase = true
      }
    } 
  }

  if(hasInt && hasLowercase && hasUppercase){
    return true  
  } else {
    return false
  }
  
}

export const inputValidation = (type, value) => {
  switch(type) {
    case 'default':
      if(value != ""){
          return true
      } else {
          return false
      }
      break;
    case 'password':
      if(value != ""){
          return true
      } else {
          return false
      }
      break;
    case 'strongPassword':
      let correctLength = (value.length >= 8) ? true : false
      let correctCase = checkCase(value)
      if(correctLength && correctCase){
        return true
      } else {
        return false
      }
      break;
    case 'email':
      const re = /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const validEmail = re.test(String(value).toLowerCase());
    	return validEmail;
      break;
    case 'phone':
      let p = /^\d{3}-\d{3}-\d{4}$/;
      let validPhone = p.test(value);
      return validPhone;
      break;
    default:
      break;
  }
}


