import AWS from "aws-sdk";
// const imageToBase64 = require('image-to-base64');

const s3 = new AWS.S3({
    accessKeyId: 'AKIARPVJ7ID5KDYV4UPP',
    secretAccessKey: '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',
    signatureVersion: "v4",
    region: 'us-east-1',
    params: { Bucket: 'floorplanbucket' },
});

export const getSignedImageUrl = async (key) => {
    const url = await s3.getSignedUrl('getObject', {
        Bucket: 'survail-thumbnails',
        Key: key,
        Expires: 36000
    });

    return url;
}

export const getSignedUrl = async (key) => {
    const params = {
        Bucket: 'floorplanbucket',
        Key: key,
        Expires: 36000
    };

    try {
        const url = await new Promise((resolve, reject) => {
            s3.getSignedUrl('getObject', params, (err, url) => {
                err ? reject(err) : resolve(url);
            });
        });

        return url;
    } catch (err) {
        if (err) {
            console.log(err)
        }
        return "";
    }
}

export const getBase64 = async (userId, planId) => {
    const key = `floorplan/${userId}/${planId}-thumbnail.jpeg`;
    const file = await s3.getObject({Key: key }).promise();
    var string64 = file.Body.toString('base64');
    return string64;
}