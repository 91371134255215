import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';

import { useEffect, Suspense, lazy } from 'react'


import Plans from "./pages/Plans"

const Plan = lazy(() => import('./pages/Plan'))

const Login = lazy(() => import('./pages/Login'))
const SignUp = lazy(() => import('./pages/SignUp'))

function App() {
  return ( 
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" render={() => (
            <Login />
          )}/>
          <Route exact path="/login" render={() => (
            <Login />
          )}/>
          <Route exact path="/plans" render={({match}) => (
            <Plans />
          )}/>
          <Route exact path="/plan/:id" render={({match}) => (
            <Plan />
          )}/>

          <Route exact path="/sign-up" render={({match}) => (
            <SignUp />
          )}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default App;