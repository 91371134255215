export const logout = (props) => {
  window.location.pathname = "/login"

  localStorage.removeItem('auth')
  localStorage.removeItem('aws-accessKey')
  localStorage.removeItem('aws-token')
  localStorage.removeItem('aws-secret')
  localStorage.removeItem('filters')
  localStorage.removeItem('username')
  localStorage.removeItem('permissions')
  localStorage.removeItem('company_id')

  console.log("logged out")
}


