import React, { useEffect } from 'react';
import { css } from '@emotion/css'
import Button from './Button'
import CircleLoader from '../components/loader/CircleLoader'

const modal = css`
    position: fixed;
    display: block;
    top: -76px;
    right: 0;
    left: 0;
    height: calc(100vh + 76px);
    width: 100vw;
    z-index: 9;`

const modalContainer = css`
    min-height: calc(100% + 46px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 4;
    pointer-events: none;`

const modalInner = css` 
        border-radius: 8px;
        background-color: #fff;
        overflow: hidden;
        position: relative;
        pointer-events: all;`

const modalOverlay = css`    
    position: fixed;
    height: calc(100vh + 76px);
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 3;`

const modalFooter = css`
    border-top: 1px solid @grey2;
    background-color: #fff;
    padding: 16px;
    position: absolute;
    bottom: 0;
    max-width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #dbdbdb;
    width: 100%;`


export default function Modal(props) { 
    // useEffect(() => {
    //     document.body.style.overflow = 'hidden'
    // }, [])

    function close(){
        props.close()
        // document.body.style.overflow = 'scroll'
    }

    function submit(){
        props.submit()
        // props.close()
        // document.body.style.overflow = 'scroll'
    }

    return (
        <div className={modal}>
            <div className={modalContainer}>
                <div className={modalInner} style={{height: props.height, width: props.width}}>
                {/*className="distribute distribute-end pt-3 pr-3 hover"*/}
                    <div style={{display: "flex", alignItems: "flex-end", flexDirection: "column", paddingTop: 24, paddingRight: 24 }} onClick={close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                    
                    <div style={{padding:24}}>
                        <h2 
                            className="fontRegular fontSize3 text-center mb-4 mt-0"
                            style={{fontSize: 28, textAlign: "center", marginBottom: 32, marginTop: 16}}
                        >{props.title}</h2>
                        {props.children}
                    </div>

                    {(!props.hideFooter) ? 
                        <div className={modalFooter}>
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                
                                {(props.isLoading) ? 
                                    <button 
                                         className={css([
                                          {
                                            backgroundColor: "#6A4AFC",
                                            borderRadius: "4px",
                                            border: "none",
                                            color: "#fff",
                                            outline: "0",
                                            padding: "14px 22px",
                                            transition: ".3s all",
                                            opacity: "0.8",
                                            userSelect: "none",
                                            border: "none"
                                          }
                                        ])}

                                    >
                                        <CircleLoader 
                                            color="#fff"
                                            size={20}
                                        />
                                    </button> 
                                : 
                                    <Button 
                                        type={"primary"}
                                        text={(props.buttonText) ? props.buttonText : "Update"}
                                        onClick={submit}

                                    />
                                }
                                
                            </div>
                        </div>
                    : ''}
                    
                </div>
            </div>
            <div className={modalOverlay} onClick={close}></div>
        </div>
    )
}