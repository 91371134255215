import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';

import Logo from '../components/Logo'
import Modal from '../components/Modal'
import LoginInput from '../components/LoginInput'
import CircleLoader from '../components/loader/CircleLoader'

import { logout } from '../util/logout'

import "../styles.css";

import { css } from '@emotion/css'

import { addPlan, getPlanById, getPlans, updatePlan, uploadImage, deletePlan } from "../api/plans"

import { getSignedUrl } from '../util/getImage';

function uid() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

function Plans(props) {
    const [loadingNewPlan, setLoadingNewPlan] = useState(false)
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [allowDelete, setAllowDelete] = useState(false)


    useEffect(() => {
      init()
      
      // initDelete()
      


      // let hasAuth =  localStorage.getItem('auth')
      // if(hasAuth){
      //   console.log("has auth")
      // } else {
      //   console.log("no auth")
      //   props.history.push("/login")
      // }
    }, [])

    function init(){
      let username = localStorage.getItem("username")
      getPlans(username).then((d) => {
        let counter = d.length
        d.forEach(async (item, index) => {
          // deleteOne(item.planId)
          // if(item.stuff.hasImage === true){
          //   let url = await awaitImage(item);
          //   d[index].url = url    
          // } else {
          //   d[index].url = null    
          // }

        let url = await awaitImage(item);
       	d[index].url = url    
          

          counter--

          if(counter === 0){
            setTimeout(() => {
              setPlans(d)  
              console.log(d)
            }, 2000)  
          }
        })  
      }).catch(function (err) {
          console.log(err)
      })
    }


    const awaitImage = async (d) => {
      return await getSignedUrl(`floorplan/${d.userId}/${d.planId}-thumbnail.jpeg`)
    }


    async function deleteSelectedPlan(){
      setLoading(true)
      console.log("delete time", selectedPlan.planId)
      let username = localStorage.getItem("username")
      console.log("username", username)
      const p = await deleteOne(username, selectedPlan.planId)

      window.location = "/plans"
      console.log(p)
      if(p){
        setShowDeleteModal(false)
        setSelectedPlan(null)
      }
    }

    async function deleteOne(username, id){
      const d = await deletePlan(username, id)
    }

    function requestCopy(d){
      console.log("plan to copy",d)
      // alert("wanna copy this plan")
      setSelectedPlan(d)
      setShowModal(true)
    }

    function requestDelete(d){
      console.log("plan to delete",d)
      // alert("wanna copy this plan")
      setSelectedPlan(d)
      setShowDeleteModal(true)
    }

    function routeTo(route){
      props.history.push(route)
    }

    async function copy(){
      setLoading(true)
      let username = localStorage.getItem("username")
      const p = await getPlanById(username, selectedPlan.planId)
      

      if(p){
        console.log("we got it", p)
        // time to close modal 
        
        function uid() {
          return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        }

        let newId = uid()
        let stuff = p[0].stuff
        stuff.name = p[0].stuff.name + " copy" 

        if(p[0].base64Img !== ""){
          uploadImage(p[0].base64Img, newId, username).then((d) => {
            addPlan(username, newId, p[0].stuff).then((d) => {
              setShowModal(false)
              setSelectedPlan(null)
              setLoading(false)
              init()
            }).catch(function (err) {
                alert("Unable to save")
                console.log(err)
                setShowModal(false)
                setSelectedPlan(null)
                setLoading(false)
            })
          }).catch(function (err) {
              alert("Unable to save")
              console.log(err)
          })  
        } else {
          addPlan(username, newId, p[0].stuff).then((d) => {
            alert("Successfully saved")
            console.log("success", d)
            setShowModal(false)
            setSelectedPlan(null)
            setLoading(false)
            init()
          }).catch(function (err) {
              alert("Unable to save")
              console.log(err)
              setShowModal(false)
              setSelectedPlan(null)
              setLoading(false)
          })
        }
      }
      
    }

    function updateDeleteName(id, v){
      console.log(v)
      console.log(selectedPlan.name)

      if(v.toLowerCase() === selectedPlan.stuff.name.toLowerCase()){
        setAllowDelete(true)
      }
    }

    function createPlan(){
      setLoadingNewPlan(true)
      let username = localStorage.getItem("username")
      const newId = uid()
      addPlan(username, newId, {name: "untitled", plan: {"rooms": []}, lines: [], scale: 3.7349832663614437, cameraNodeSize: 2.5, hasImage: false, hubspotLink: "", opacity: 80}).then((d) => {
          
          console.log("success", d)
          console.log("new id", newId)
          window.location = "/plan/"+newId
          
        }).catch(function (err) {
            
        })
    }

    return ( 
      <div style={{maxWidth: 900, margin: "0 auto", paddingBottom: 1000}}>
        <div className="full-width distribute distribute-between" style={{padding: "16px 0"}}>
          <Logo
            width={100}
            height={20}
          />

          <div onClick={() => logout()}>Logout</div>
        </div>
        <div className="full-width distribute distribute-between" style={{padding: "16px 0"}}>
          <h2>Plans</h2>
          {/*<a 
          className="button" href="/plan/new">Add</a>*/}

            {(!loadingNewPlan) ? 
              <button 
                className="button"
                style={{fontSize: 15, padding: "16px 22px"}}
                onClick={createPlan}
              >Add</button>
            : <button className="button" style={{fontSize: 15, padding: "16px 22px"}}>
                <CircleLoader 
                color="#fff"
                size={20}
            /></button>}
        </div>

        <ul style={{margin: 0, padding: 0, listStyle: "none"}}>
          {(plans.length > 0) && 
            <>
              {plans.map((d,i) => {
                return(
                  <li style={{width: "24%", display: "inline-block", padding: "16px 0.5%"}} key={i}>
                    <div style={{textDecoration: "none"}} className={css`
                      position: relative;
                      display: block;
                      clear: all;
                      border-radius: 8px;
                      overflow: hidden;
                      transition: all 0.3s;

                      
                    `}>
                      {console.log(d)}
                          <div onClick={() => routeTo("/plan/"+d.planId)} style={{height: 120, width: "100%", backgroundImage: "url("+d.url+")", backgroundSize: "cover", position: "relative"}}>
                            <div className="bg-overlay" style={{background: "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.2)", width: "100%", height: "100%", top: 0, left: 0, transition: "0.3s all"}}></div>
                            {/*{(d.url !== null) ? 
                              <img src={d.url} style={{height: 120, width: "100%", backgroundColor: "#e6e6e6"}} />
                            : ""}*/}
                          </div>

                          <p
                            className={css`
                              transition: 0.2s all;
                              &:hover {
                                cursor: pointer;
                                color: #6A4AFC;
                              }
                            `} 
                            onClick={() => routeTo("/plan/"+d.planId)} style={{paddingBottom: 4, paddingLeft: 16}}>{(d.stuff?.name) ? d.stuff.name : "Untitled"}</p>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                          <svg 
                            onClick={() => requestCopy(d)}
                            className={css`
                              position: absolute;
                              top: 8px;
                              right: 8px;
                              transition: 0.3s all;
                              color: #fff;
                              padding: 8px;
                              border-radius: 4px;
                              
                              
                              &:hover {
                                cursor: pointer;

                              }
                            `}
                            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                        
                        <svg 
                            onClick={() => requestDelete(d)}
                            className={css`
                              position: absolute;
                              top: 8px;
                              left: 8px;
                              transition: 0.3s all;
                              color: red;
                              padding: 8px;
                              border-radius: 4px;
                              
                              
                              &:hover {
                                cursor: pointer;

                              }
                            `}
                            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                      </div>
                    </div>
                    
                  </li>
                )
              })}
          </>}
        </ul>

        {(showModal) ? 
          <Modal
            close={() => setShowModal(false)}
            title="COPY this floorplan?"
            submit={copy}
            width={480}
            height={320}
            buttonText={"Yes, continue"}
            isLoading={loading}
          >
            <p style={{lineHeight: 1.6}}>By selecting "yes", a new version of "{selectedPlan.stuff.name}" will be created and saved. This process could take a minute or two to complete, depending on the size of the plan and reference image. </p>
          </Modal>
        : ""}


        {(showDeleteModal) ? 
          <Modal
            close={() => setShowDeleteModal(false)}
            title="DELETE this floorplan?"
            submit={deleteSelectedPlan}
            width={480}
            height={360}
            buttonText={"DELETE"}
            // isLoading={true}
            isLoading={loading}
            hideFooter={!allowDelete}
          >
            <p style={{lineHeight: 1.6}}>By selecting "delete", you will permanently delete "{selectedPlan.stuff.name}". This action may not be undone. Type "<span style={{textDecoration: "underline"}}>{selectedPlan.stuff.name}</span>" to confirm.</p>

            <LoginInput 
                name="Plan name"
                id="name"
                type="default"
                defaultValue={""}
                update={updateDeleteName}
                errorMessage=""
              />
          </Modal>
        : ""}
      </div>
    );
}

export default withRouter(Plans);
