import { authorized } from './api';
import Amplify, { Auth, API } from 'aws-amplify';

function uid() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const poolId = "us-east-1_LeWlttXPl"
const clientId = "2cgm3ouv01v3f93as52qia1c9k"
const region = "us-east-1"




// GET, ADD, UPDATE, DELETE

export function addPlan(userId, uid, stuff) { 
    let t = localStorage.getItem('auth')
    let a = JSON.parse(t)
    let token = a.token.jwtToken;

    Amplify.configure({
      Auth: {
        userPoolId: poolId,
        region: region,
        userPoolWebClientId: clientId
      }, 
      API: {
        endpoints: [
            {
                name: "floorplanapi",
                endpoint: "https://zau8y52b54.execute-api.us-east-1.amazonaws.com/dev"
            }
        ]
      }
    });

    const apiName = 'floorplanapi';
    const path = '/floorplan'; 
    let body = {
        body: {
            payload: {
                userId: userId,
                planId: uid, 
                stuff:  stuff
            },
            requestRoute: "ADD"    
        },
        headers: {
            "Authorization": token
        }
    }

    console.log("HERE IS THE BODY", body)

    return new Promise(function(resolve, reject) {
        API.post(apiName, path, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
            reject(error)
       });    
    })       
}

export function updatePlan(userId, planId, stuff) {
    let t = localStorage.getItem('auth')
    let a = JSON.parse(t)
    let token = a.token.jwtToken;

    Amplify.configure({
      Auth: {
        userPoolId: poolId,
        region: region,
        userPoolWebClientId: clientId
      }, 
      API: {
        endpoints: [
            {
                name: "floorplanapi",
                endpoint: "https://zau8y52b54.execute-api.us-east-1.amazonaws.com/dev"
            }
        ]
      }
    });

    const apiName = 'floorplanapi';
    const path = '/floorplan';

    let body = {
        body: {
            payload: {
                userId: userId,
                planId: planId,
                stuff: stuff    
            },
            requestRoute: "UPDATE"    
        },
        headers: {
            "Authorization": token
        }
    }

    return new Promise(function(resolve, reject) {
        API.post(apiName, path, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
            reject(error)
       });    
    })   
}

export function deletePlan(userId, planId) {
    let t = localStorage.getItem('auth')
    let a = JSON.parse(t)
    let token = a.token.jwtToken;

    Amplify.configure({
        Auth: {
          userPoolId: poolId,
          region: region,
          userPoolWebClientId: clientId
        },
        API: {
          endpoints: [
              {
                  name: "floorplanapi",
                  endpoint: "https://zau8y52b54.execute-api.us-east-1.amazonaws.com/dev"
              }
          ]
        }
    });

    const apiName = 'floorplanapi';
    const path = '/floorplan';
    let body = {
        body: {
            payload: {
                userId: userId,
                planId: planId,    
            },
            requestRoute: "DELETE"    
        },
        headers: {
            "Authorization": token
        }
    }

    return new Promise(function(resolve, reject) {
        API.post(apiName, path, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
            reject(error)
       });    
    })
}

export function getPlans(userId) { 

    let t = localStorage.getItem('auth')
    let a = JSON.parse(t)
    if(a === null){
      window.location = "/login"
    }
    let token = a.token.jwtToken;

    Amplify.configure({
        Auth: {
          userPoolId: poolId,
          region: region,
          userPoolWebClientId: clientId
        },
        API: {
          endpoints: [
              {
                  name: "floorplanapi",
                  endpoint: "https://zau8y52b54.execute-api.us-east-1.amazonaws.com/dev"
              }
          ]
        }
    });

    const apiName = 'floorplanapi';
    const path = '/floorplan';
    let body = {
        body: {
            payload: {
                userId: userId,
                planId: null,    
            },
            requestRoute: "GET_SHORT"    
        },
        headers: {
            "Authorization": token
        }
    }

    return new Promise(function(resolve, reject) {
        API.post(apiName, path, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
            reject(error)
       });    
    })    
}


export function uploadImage(img, planId, userId) { 
  console.log(img)
  console.log(planId)
  console.log(userId)
    let t = localStorage.getItem('auth')
    let a = JSON.parse(t)
    let token = a.token.jwtToken;

    Amplify.configure({
        Auth: {
          userPoolId: poolId,
          region: region,
          userPoolWebClientId: clientId
        },
        API: {
          endpoints: [
              {
                  name: "floorplanapi",
                  endpoint: "https://zau8y52b54.execute-api.us-east-1.amazonaws.com/dev"
              }
          ]
        }
    });

    const apiName = 'floorplanapi';
    const path = '/uploadimage';
    let body = {
        body: {
            payload: {
                userId: userId,
                planId: planId,
                image: img.replace(/^data:image\/[a-z]+;base64,/, "")
            },
            requestRoute: "GET_SHORT"    
        },
        headers: {
            "Authorization": token
        }
    }

    return new Promise(function(resolve, reject) {
        API.post(apiName, path, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
            reject(error)
       });    
    })    
}

export function getAllPlans(userId) {
    
}

export function getPlanById(userId, planId) {
    let t = localStorage.getItem('auth')
    let a = JSON.parse(t)
    if(a === null){
      window.location = "/login"
    }

    console.log("AAAAAAA", a)
    let token = a.token.jwtToken;

    

    Amplify.configure({
        Auth: {
          userPoolId: poolId,
          region: region,
          userPoolWebClientId: clientId
        },
        API: {
          endpoints: [
              {
                  name: "floorplanapi",
                  endpoint: "https://zau8y52b54.execute-api.us-east-1.amazonaws.com/dev"
              }
          ]
        }
    });

    const apiName = 'floorplanapi';
    const path = '/floorplan';
    let body = {
        body: {
            payload: {
                userId: userId,
                planId: planId,    
            },
            requestRoute: "GET_ONE"    
        },
        headers: {
            "Authorization": token
        }
    }

    return new Promise(function(resolve, reject) {
        API.post(apiName, path, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
            reject(error)
       });    
    })       
}

