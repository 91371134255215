import React from 'react';
import { css } from '@emotion/css'

export default function Button(props) {

    return ( 
        <>

            <button
                className={css([
                  {
                    backgroundColor: "#6A4AFC",
                    borderRadius: "4px",
                    border: "none",
                    color: "#fff",
                    outline: "0",
                    padding: "14px 22px",
                    transition: ".3s all",
                    userSelect: "none",
                    '&:hover': {
                      cursor: "pointer",
                      opacity: "1"
                    },
                  },

                  props.type === "primary" && {
                    border: "none"
                  },
                  
                  props.type === "secondary" && {
                    backgroundColor: 'transparent',
                    border: "1px solid #CDCDCB",
                    width: "100%",
                    color: "#000"
                  },

                  props.type === "tertiary" && {
                    backgroundColor: "#282452",
                    background: "#282452",
                    border: "none",
                    '&:hover': {
                      background: "linear-gradient(180deg, #5481F1 0%, #7F33F1 100%)"
                    },
                  }


                ])}
                onClick={props.onClick}
                >
                {props.text}
            </button>
        </>
    )
}