import axios from 'axios';
import dotenv from 'dotenv';

import { logout } from '../util/logout'
dotenv.config()

export const baseURL = `${process.env.REACT_APP_BASE_API_URL}`

const instance = axios.create({
  baseURL: baseURL
});

let t = localStorage.getItem('auth')


export const authorized = () => {
	if(t !== null){
		let u = localStorage.getItem('username')
		let a = JSON.parse(t)
		let token = a.token.jwtToken;
		instance.defaults.headers.common['Authorization'] = token;
		instance.defaults.headers.common['username'] = u
		return instance;	
	} else {
		// logout()
		
	}
}


