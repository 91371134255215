import { useState, useEffect } from 'react';
import { inputValidation } from '../validation/input';

import { css } from '@emotion/css'

export default function LoginInput(props) {
    const [error, setError] = useState(false);
    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        setValue(props.defaultValue)
    }, [props.defaultValue])

    function update(type, value){
        props.update(props.id, value)
        setValue(value)
        let valid = inputValidation(type, value)
        if(props.validation){
            props.validation(props.id, valid)
        }
        if(valid){
            setError(false)
        } else {
            setError(true)
        }
    }

    return (
        <>
            <div className={css([
                    {
                        backgroundColor: "#fff",
                        border: "2px solid #d3d3d3",
                        display: "flex", 
                        flexDirection: "column",
                        borderRadius: "4px",
                        '&:focus-within': {
                          border: "2px solid blue",
                        }
                    }
                ])}>
                <label 
                    htmlFor={props.id}
                    style={{userSelect: "none", paddingTop: 8, paddingLeft: 16, color: "#888", width: "100%"}}
                >
                    {props.name}
                </label>
                <input 
                	id={props.id}
                    type={props.type}
                	onChange={e => update(props.type, e.target.value)} 
                    placeholder={props.placeholder}
                    onBlur={props.onBlur}
                    value={value}
                	autoComplete={"off"}
                    style={{outline: "none", border: "none", display: "block", paddingBottom: 8, paddingLeft: 16, fontSize: 16, paddingTop: 4}}
                />
            </div>
            {(error && props.errorMessage) ?
                <div className="error mt-1">
                    {props.errorMessage}
                </div>
            : ""}
            
        </>
    )
}